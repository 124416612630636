var $welcomeWrapper, $panoWrapper;

function isIE(){
	'use strict';
	return ( window.navigator.userAgent.indexOf("MSIE") >= 0 || window.navigator.userAgent.indexOf('Trident/') >= 0 );
}

function init_krpano(){

	'use strict';

	var vars = {},
		swfFile,
		xmlFile;

	vars.langvar = jQuery('.begin-link').data('lang');

	swfFile = "krpano.swf";
	xmlFile = "krpano-main.xml";

	embedpano({
		swf : swfFile, 
		xml : xmlFile, 
		target : "pano",  
		html5 : isIE() ? "auto" : "prefer", 
		passQueryParameters : true,  
		consolelog : false,  
		vars : vars,
		wmode : "transparent"
	});

	jQuery('.begin-link').on( 'click', function(){
		hide_front_page();
		return false;
	});

	jQuery('.disabled-link').on('click', function(){
		return false;
	});
}

function hide_front_page(){

	'use strict';
	
	jQuery('body').addClass('play');

	$welcomeWrapper.animate({
		'opacity' : 0
	}, 350, 'swing');

	setTimeout(function(){
		$welcomeWrapper.css({'display':'none'});
	}, 350);
}

function show_front_page(){

	'use strict';

	jQuery('body').addClass('play');

	$welcomeWrapper.css({'display':''}).animate({
		'opacity' : 1
	}, 350, 'swing');
}

(function( $ ){

	'use strict';
	
	jQuery(window).bind("load", function() {

		$welcomeWrapper = jQuery('#welcome');
		$panoWrapper = jQuery('#pano');

		imagesLoaded( $welcomeWrapper, function() {
			init_krpano();
		});

	});

}(jQuery));